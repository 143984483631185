var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper d-flex justify-content-between flex-column flex-grow-0"},[_c('div',{staticClass:"p-2"},[_c('div',[_c('h5',{staticClass:"app-label section-label mb-1"},[_vm._v(" CREAR CITA MÉDICA ")]),_c('validation-observer',{ref:"refFormObserver"},[_c('fieldset',{staticClass:"form-group"},[_c('legend',{staticClass:"col-form-label"},[_vm._v("Asegurado")]),_c('input',{staticClass:"form-control",attrs:{"readonly":""},domProps:{"value":_vm.insuredName}})]),_c('b-form-group',{attrs:{"label":"Tipo de Consulta"}},[_c('validation-provider',{attrs:{"name":"Tipo de Consulta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.treatmentTypes,"label":"name","reduce":function (item) { return item.id; },"clearable":false,"disabled":true},model:{value:(_vm.formData.treatment_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "treatment_type_id", $$v)},expression:"formData.treatment_type_id"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Especialidad *"}},[_c('validation-provider',{attrs:{"name":"Especialidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.specialties,"label":"name","clearable":false,"placeholder":"Selecciona...","disabled":_vm.isReconsult},on:{"input":_vm.handleMedicalCenter},model:{value:(_vm.formData.specialty),callback:function ($$v) {_vm.$set(_vm.formData, "specialty", $$v)},expression:"formData.specialty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Centro de Salud *"}},[_c('validation-provider',{attrs:{"name":"Centro de Salud","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalCenters,"label":"name","reduce":function (item) { return item.id; },"placeholder":"Selecciona...","disabled":_vm.isReconsult},on:{"input":_vm.handleMedicalUnit},model:{value:(_vm.formData.medical_center_id),callback:function ($$v) {_vm.$set(_vm.formData, "medical_center_id", $$v)},expression:"formData.medical_center_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isReconsult)?_c('b-form-group',{attrs:{"label":"Consultorio *"}},[_c('validation-provider',{attrs:{"name":"Consultorio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalUnits,"label":"name","reduce":function (item) { return item.id; },"placeholder":"Selecciona...","disabled":_vm.isReconsult},on:{"input":_vm.handleAvailability},model:{value:(_vm.formData.medical_unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "medical_unit_id", $$v)},expression:"formData.medical_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2435749199)})],1):_vm._e(),(_vm.isReconsult)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha *"}},[_c('validation-provider',{attrs:{"name":"Fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.availableDatesMap,"label":"date","placeholder":"Selecciona...","clearable":false},on:{"input":_vm.goToDate},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm._f("getDate")(option.date))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm._f("getDate")(option.date))+" ")]}}],null,true),model:{value:(_vm.formData.calendar),callback:function ($$v) {_vm.$set(_vm.formData, "calendar", $$v)},expression:"formData.calendar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3821296944)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Hora *"}},[_c('validation-provider',{attrs:{"name":"Hora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.availableTimes,"label":"time","clearable":false},model:{value:(_vm.formData.time),callback:function ($$v) {_vm.$set(_vm.formData, "time", $$v)},expression:"formData.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1466374395)})],1)],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Motivo de la Consulta *"}},[_c('validation-provider',{attrs:{"name":"Motivo de la Consulta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.formData.reason),callback:function ($$v) {_vm.$set(_vm.formData, "reason", $$v)},expression:"formData.reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":_vm.handleSubmit}},[_vm._v("Guardar")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }